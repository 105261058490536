<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <div
      class="container flex items-center gap-10 md:justify-between md:gap-20"
      :class="[
        section.styles.containerAlignment.value,
      ]"
    >
      <div
        v-if="section.data?.value.shownElements?.text"
        class="md:w-1/2 md:max-w-[35rem]"
        :class="[
          section.styles.textAlignment.value,
        ]"
        v-html="sanitize(section.data?.value.text[section.lang.value]?.content)"
      />
      <div
        class="flex justify-center md:w-1/2 md:max-w-[35rem]"
      >
        <div
          v-if="section.data?.value.shownElements?.image"
          class="overflow-hidden"
          :class="[
            section.styles.imageSize.value,
            section.styles.imageCrop.value,
            section.styles.cornerRadius.value,
            {
              'bg-(fg-muted)': !section.data?.value?.image?.source,
            },
          ]"
        >
          <img
            v-if="section.data?.value?.image?.source"
            :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-1280,c-at_max/' : ''}${section.data?.value?.image?.source}`"
            class="h-full object-cover"
            :alt="sanitize(section.data?.value.text[section.lang.value]?.content) || eventTitle"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const section = usePageSection(props);
const eventTitle = inject('eventTitle');
</script>
